import UserActionTypes from "./user.types";
const {
  CHECK_USER_SESSION,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_OUT,
  AUTH_ERROR,
  CLEAR_ERRORS,
} = UserActionTypes;

const INITIAL_STATE = {
  currentUser: null,
  token: null,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_USER_SESSION:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        token: action.payload.data,
        error: null,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        token: null,
        error: action.payload.email,
      };
    case SIGN_IN_FAILURE:
    case AUTH_ERROR:
      return {
        ...state,
        token: null,
        error: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        token: null,
        currentUser: null,
        error: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
