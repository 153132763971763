import AdminActionTypes from './admin.types'
const { GET_USERS, SET_ERROR, CLEAR_ERRORS } = AdminActionTypes

const INITIAL_STATE = {
  users: [],
  // user: null,
  error: null,
}

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        error: null,
      }
    // case EDIT_USER:
    //   return {
    //     ...state,
    //     user: action.payload.data,
    //     error: null,
    //   };
    // case DELETE_USER:
    //   return {
    //     ...state,
    //     users: state.users.filter((user) => user._id !== action.payload.id),
    //     error: null,
    //   };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}

export default adminReducer
