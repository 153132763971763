import React, { Fragment, lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

import '@fortawesome/fontawesome-free/css/all.min.css'

import Alert from './components/Alert'
import ErrorBoundary from './components/error-boundary/error-boundary.component'
import Spinner from './components/spinner/Spinner'

const Homepage = lazy(() => import('./views/home/Homepage'))
const Login = lazy(() => import('./views/auth/Login'))
const Register = lazy(() => import('./views/auth/Register'))

// private views
const UserLayout = lazy(() => import('./layouts/User'))
const AdminLayout = lazy(() => import('./layouts/Admin'))

const App = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <Fragment>
      <Alert />
      <Switch>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Route exact path="/" component={Homepage} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />

            <Route path="/admin" component={AdminLayout} />
            <Route path="/user" component={UserLayout} />
          </Suspense>
        </ErrorBoundary>
      </Switch>
    </Fragment>
  )
}

export default App
