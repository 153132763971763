import { v4 as uuidv4 } from 'uuid'
import AlertActionTypes from './alert.types'
const { SET_ALERT, REMOVE_ALERT } = AlertActionTypes

// set alert
export const setAlert =
  (msg, color, timeout = 3000) =>
  (dispatch) => {
    const id = uuidv4()
    dispatch({ type: SET_ALERT, payload: { msg, color, id } })

    setTimeout(() => dispatch(removeAlert(id)), timeout)
  }

export const removeAlert = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ALERT, payload: id })
}
