import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAlerts } from "../redux/alert/alert.selectors";
import { removeAlert } from "../redux/alert/alert.actions";

const Alert = ({ alerts, removeAlert }) => {
  return (
    <Fragment>
      {alerts.length
        ? alerts.map((alert) => (
            <div
              key={alert.id}
              className={
                "text-white px-4 mx-auto py-2 border-0 rounded absolute bottom-0 right-0 z-50 mb-4 bg-" +
                alert.color +
                "-500"
              }
            >
              <span className="text-md inline-block mr-3 align-middle">
                <i className="fas fa-bell" />
              </span>
              <span className="inline-block align-middle mr-8">
                <b className="normal-case">{alert.msg}</b>
              </span>
              <button
                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-6 outline-none focus:outline-none"
                onClick={() => removeAlert(alert.id)}
              >
                <span>×</span>
              </button>
            </div>
          ))
        : null}
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  alerts: selectAlerts,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
